.primary-color {
    background-color: #000000;
    /* background-color: #1B4673; */
}
.bg-content {
    background-color: #F5F5F5;
}
.bg-mrchampagne{
    background-image: url('/public/images/logo_bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    height: 100vh;

}
.jmz-button{
    background-color: #000000 !important    ;
    color: #FFFFFF;
}
.fc .fc-toolbar {
    flex-direction: column;
    gap:10px
}

.tsoft-event-calendar{
    text-overflow: ellipsis;
    overflow: hidden;
}

.border-jmz{
    border: 1px solid #f0f0f0 !important;
}

.jmz-mayusculas{
    text-transform: uppercase;
}
.jmz-paper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: white;
    border-radius: 0.25rem;
    border: 1px solid rgba(98,105,118,.16);
    transition: transform .3s ease-out,opacity .3s ease-out,box-shadow .3s ease-out;
    box-shadow: rgb(30 41 59 / 4%) 0 2px 4px 0;
/* 
    background-image: url('/public/images/logo_bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%; */
    /* height: 100vh; */
}

.jmz-card-header {
    padding: 1rem 1.25rem;
    margin-bottom: 0;
    color: #626976;
    background-color: #fafbfc;
    border-bottom: 1px solid rgba(98,105,118,.16);
}

.jmz-card-title {
    display: block;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.5rem;
}

.jmz-datepicker{
    border: 1px solid #1818184d;
    padding: 10px;
    border-radius: 4px;
    padding-left: 15px;
    min-width: 100%;
}
.jmz-datepicker-calendar{
    border: 1px solid red;
}
.react-datepicker__month-container{
    font-family: "Open Sans";
}
.jmz-table{
    border: none;
    /* border-right: solid 3px #DDEFEF; */
    border-right: 1px solid #edf2f7;
    border-collapse: separate;
    border-spacing: 0;
}

.jmz-table thead th {
    /* background-color: #000; */
    /* background-color: #1B4673; */
    border: none;
    /* color: #fff; */
    color: #4a5568;
    border-bottom: 2px solid #edf2f7;
    border-right: 2px solid #edf2f7;
    padding: 10px;
    text-align: center;
    white-space: nowrap;
}

.jmz-table tfoot th {
    /* background-color: #000; */
    /* background-color: #1B4673; */
    border: none;
    color: #4a5568;
    padding: 10px;
    text-align: center;
    white-space: nowrap;
    border-bottom: 2px solid #edf2f7;

}


.jmz-table tbody td {
    border-bottom: 2px solid #edf2f7;
    color: #333;
    padding: 10px;
    white-space: nowrap;
    background-color: #fff;
}

.jmz-wrapper {
    position: relative;
}

.jmz-scroll {
    margin-left: 119px;
    overflow-x: scroll;
    overflow-y: visible;
    padding-bottom: 5px;
    width: auto;
}

.jmz-table .jmz-sticky-col {
    /* border-left: 3px solid #f2f2f2; */
    border-right: 2px solid #edf2f7;
    left: 0;
    position: absolute;
    /* top: auto; */
    width: 120px;
}

.jmz-current-month {
    display: flex;
    align-self: center;
    flex-direction: column;
    text-align: center;
    padding: 15px;
}

.jmz-outline {
    border: 1px solid #0000001e;
}

.cursor-pointer{
    cursor: pointer;
}
